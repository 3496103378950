import { env } from './env';
import resourceList from '@/assets/resources/index.json'
console.log(env.isPro)
const api = {
  async getById([id1, id2]) {
    const find = resourceList.find(item => ~item.name.indexOf(id1) && ~item.name.indexOf(id2))
    if (find) {
      const id = find.name
      const ossUrl = (id) => `https://defimelabs.oss-cn-hongkong.aliyuncs.com/emojimaker/${id}`
      return ossUrl(id)
    }
  }
}
export default api