const assetList = require("@/assets/emojis");

export function loadEmojis() {
  const list = assetList.map(emoji => ({
    asset: require(`@/assets/emojis/${emoji.name}`),
    ...emoji,
  }))
  return list;
}

export function shuttle(array) {
  return array.sort(() => 0.5 - Math.random())
}

export function pickRandom(array) {
  const rand = Math.trunc(Math.random() * array.length);
  return array[rand]
}