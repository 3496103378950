<template>
  <div class="try">
    <Avataaars :height="120" class="avatar" />
    <NFTMaker />
  </div>
</template>
<script>
import NFTMaker from "./NFTMaker";
export default {
  name: "Try",
  components: {
    NFTMaker,
  },
};
</script>
<style lang="less" scoped>
.try {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .avatar {
    transition: all 0.1s;
    cursor: pointer;
    margin-bottom: 10px;
    @media (any-hover: hover) {
      &:hover {
        transform: scale(1.1);
      }
      &:active {
        transform: scale(1);
      }
    }
    @media (any-hover: none) {
      &:active {
        transform: scale(1.1);
      }
    }
  }
}
</style>
