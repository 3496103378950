<template>
  <div class="nft-maker">
    <h1>Super Emoji NFT Maker</h1>
    <div class="content">
      <div class="dice" @click="shuttle">
        <span>🎲</span>
      </div>
      <div class="left">
        <img
          ref="left"
          :src="left.asset"
          @click="() => shuttle('left')"
          @load="() => loadImage('left')"
          alt="emoji"
        />
      </div>
      <div class="right">
        <img
          ref="right"
          :src="right.asset"
          @click="() => shuttle('right')"
          @load="() => loadImage('right')"
          alt="emoji"
        />
      </div>
      <div class="emoji-result">
        <span v-if="!mixedImage">{{ mixedText }}</span>
        <img ref="result" v-else :src="mixedImage" alt="mixed emoji" />
      </div>
    </div>
    <div class="share">
      <i-share-one theme="outline" size="24" fill="#333" />
    </div>
  </div>
</template>

<script>
import api from "@/utils/api";
import { loadEmojis, pickRandom } from "@/utils/index";

export default {
  name: "NFTMaker",
  components: {},
  data() {
    const assetList = loadEmojis();
    return {
      assetList,
      left: pickRandom(assetList),
      right: pickRandom(assetList),
      mixedImage: null,
      mixedText: "boom",
      isLoadImage: false,
    };
  },
  mounted() {
    this.mixBoom();
  },
  methods: {
    async sleep(delay = 100) {
      return new Promise((resolve) => setTimeout(resolve, delay));
    },
    async loadImage(asset) {
      console.log("loadImage", asset);
      console.log(this.left, this.right);
      this.isLoadImage = true;
      if (asset === "left") {
        this.mixBoom();
        this.$anime({
          targets: this.$refs.left,
          duration: 800,
          keyframes: [{ scale: "120%" }],
        });
        await this.sleep(200);
        this.$anime({
          targets: this.$refs.result,
          duration: 800,
          keyframes: [{ scale: "120%" }],
        });
      }
      if (asset === "right") {
        this.mixBoom();
        this.$anime({
          targets: this.$refs.right,
          duration: 800,
          keyframes: [{ scale: "120%" }],
        });
        await this.sleep(200);
        this.$anime({
          targets: this.$refs.result,
          duration: 800,
          keyframes: [{ scale: "120%" }, { scale: "100%" }],
        });
      }
      this.isLoadImage = false;
    },
    async shuttle(direction) {
      if (direction === "left") {
        return (this.left = pickRandom(this.assetList));
      }
      if (direction === "right") {
        return (this.right = pickRandom(this.assetList));
      }
      this.left = pickRandom(this.assetList);
      this.right = pickRandom(this.assetList);
    },
    async mixBoom() {
      const ids = [
        this.left.name.replace(".svg", "").split(".")[0],
        this.right.name.replace(".svg", "").split(".")[0],
      ];
      const response = await api.getById(ids);
      if (response) {
        this.mixedImage = response;
      } else {
        this.mixedText = "oooops, try another...";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.nft-maker {
  width: 90%;
  min-height: 400px;
  max-width: 960px;
  height: 80%;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border: 1px solid #dbdbdb;
  justify-content: space-between;
  h1 {
    font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif,
      Arial, sans-serif;
    text-align: left;
    margin: 30px;
  }
  .content {
    display: flex;
    justify-content: space-around;
    .dice {
      cursor: pointer;
      border-radius: 5px;
      height: 80px;
      width: 80px;
      transition: all 0.1s linear;
      &:hover {
        transform: scale(1.1) rotate(20deg);
      }
      &:active {
        transform: scale(1);
      }
      span {
        font-size: 3.5em;
      }
      img {
        max-width: 100%;
      }
    }
    .left,
    .right,
    .emoji-result {
      cursor: pointer;
      border-radius: 5px;
      // background-color: whitesmoke;
      height: 80px;
      width: 80px;
      // border: solid 5px lightgrey;
      img {
        max-width: 100%;
        transition: all 0.1s linear;
        &:hover {
          transform: rotate(20deg);
        }
        &:active {
          transform: rotate(20deg);
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .content {
      .left,
      .right,
      .emoji-result {
        height: 60px;
        width: 60px;
      }
    }
  }
  .share {
    height: 120px;
  }
}
</style>
